<template>
  <div class="page">
    <section class="hero is-banner is-primary">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-mobile">
            <div class="column">
              <h2 class="is-3 title">
                {{ $t('Toplines') }}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="columns is-mobi is-mini-gap is-multiline">
          <div class="column mt-2">
            <SearchFilter
              ref="searchFilter"
              @filter="fetchData"
            />
          </div>
          <div class="column is-narrow">
            <AddToplineButton
              class="mr-2 mt-2"
              :on-add-manually="handleOpenAddTopline"
              @added="() => {
                fetchData();
                fetchTravelWebsite();
              }"
            />
            <a-button
              v-role="[ROLES.ADMIN, ROLES.SALES]"
              class="ant-btn ant-btn-lg ant-btn-primary mr-2 mt-2"
              :loading="loadingExportBtn"
              @click="handleExport"
            >
              {{ $t('Export to CSV') }}
            </a-button>
            <Modal width="80vw">
              <template #handler="{ show }">
                <a-button
                  class="ant-btn ant-btn-lg ant-btn-primary mt-2"
                  @click="() => {
                    show();
                  }"
                >
                  {{ $t('configure-columns') }}
                </a-button>
              </template>
              <template #default="{ hide }">
                <ColumnConfigurations
                  :value="selectedColumns"
                  @cancel="hide"
                  @close="() => {
                    hide();
                    fetchConfig();
                  }"
                />
              </template>
            </Modal>
          </div>
        </div>

        <div class="my-2">
          <a-alert
            v-if="!selectedColumns.length"
            :message="$t('Please choose the column on Configure Columns first.')"
            type="warning"
          />
        </div>

        <a-table
          row-key="id"
          :columns="columns"
          :data-source="data"
          :loading="isFetching"
          :custom-row="rowClicked"
          :pagination="pagination"
          @change="handleTableChange"
        >
          <template #reservationDate="{ record }">
            {{ $filters.date(record.reservationDate, { format: dateFormat }) }}
          </template>

          <template #checkin="{ record }">
            {{ $filters.date(record.checkin, { format: dateFormat }) }}
          </template>

          <template #checkout="{ record }">
            {{ $filters.date(record.checkout, { format: dateFormat }) }}
          </template>

          <template #totalSalesAmount="{ record }">
            {{ $filters.number(record.totalSalesAmount) }}
          </template>

          <template #salesWithoutOtaFees="{ record }">
            {{ $filters.number(record.salesWithoutOtaFees) }}
          </template>

          <template #otaFees="{ record }">
            {{ $filters.number(record.otaFees) }}
          </template>

          <template #otaFeeRates="{ record }">
            {{
              $filters.number(record.otaFeeRates, {
                minimumFractionDigits: 1,
                maximumFractionDigits: 1
              })
            }}%
          </template>
        </a-table>

        <Modal ref="addModal" width="85vw">
          <template #default="{ hide }">
            <AddTopLineForm
              :value="selectedData"
              @close="() => {
                hide();
                fetchData();
                fetchTravelWebsite();
              }"
              @cancel="() => {
                hide();
                selectedData = undefined;
              }"
            />
          </template>
        </Modal>
      </div>
    </section>
  </div>
</template>

<i18n>
{
  "en": {
    "Please choose the column on Configure Columns first.":
    "Please choose the column on Configure Columns first.",
    "configure-columns": "Configure Columns",
  },
  "ja": {
    "Please choose the column on Configure Columns first.": "表示する項目を最初に設定してください。",
    "configure-columns": "項目設定",
  }
}
</i18n>

<script>
import { saveAs } from 'file-saver';
import { ROLES } from '@/config/roles';
import { DATE_FORMAT } from '@/config/dates';
import Modal from '@/components/ModalWide';
import pagination from '@/components/mixins/pagination';
import AddToplineButton from './components/AddToplineButton';
import AddTopLineForm from './components/AddTopLineForm';
import SearchFilter from './components/SearchFilter';
import ColumnConfigurations from './components/ColumnConfigurations';
import { getLocale } from '@/utils/locale';
import { getDefaultLang } from '@/utils/language';
import { columnMap } from './components/column-configuration/columnsList';

export default {
  name: 'TopLinesList',
  components: {
    AddToplineButton,
    SearchFilter,
    Modal,
    ColumnConfigurations,
    AddTopLineForm,
  },
  mixins: [pagination],
  data() {
    return {
      dateFormat: DATE_FORMAT.JA,
      ROLES,
      loadingExportBtn: false,
      isFetching: false,
      selectedColumns: [],
      data: [],
      selectedData: undefined,
    };
  },
  computed: {
    columns() {
      const cols = [];
      this.selectedColumns.forEach((col) => {
        cols.push({
          title: this.$t(col.name),
          dataIndex: col.value,
          key: col.value,
          slots: { customRender: col.value },
          width: col.width || null,
        });
      });

      return cols;
    },
  },
  created() {
    this.fetchConfig();
    this.fetchData();
  },
  methods: {
    async handleTableChange(pagination) {
      await this.preparePagination(pagination);
      await this.fetchData();
    },
    fetchData() {
      this.$store.commit('SHOW_FULLSCREEN_LOADER');
      this.isFetching = true;

      return this.$store.dispatch('rms-toplines/list', this.$route.query)
        .then((resp) => {
          const { data, pagination } = resp;

          this.data = data;

          this.setPagination(pagination);
        })
        .finally(() => {
          this.$store.commit('HIDE_FULLSCREEN_LOADER');
          this.isFetching = false;
        });
    },
    fetchConfig() {
      this.isFetching = true;

      return this.$store.dispatch('rms-toplines/getConfiguration')
        .then((response) => {
          const { data } = response;

          if (data) {
            this.selectedColumns = data.activeColumns.map((name) => columnMap[name]);
          }
        })
        .finally(() => {
          this.isFetching = false;
        });
    },
    fetchTravelWebsite() {
      this.$refs.searchFilter.triggerFetchTravelWebiste();
    },
    handleOpenAddTopline() {
      this.$refs.addModal.show();
    },
    rowClicked(record) {
      return {
        onClick: () => {
          this.selectedData = record;
          this.handleOpenAddTopline();
        },
      };
    },
    handleExport() {
      this.$store.commit('SHOW_FULLSCREEN_LOADER');
      this.loadingExportBtn = true;

      const query = {
        ...this.$route.query,
        locale: getLocale() || getDefaultLang(),
      };

      return this.$store.dispatch('rms-toplines/exports', query)
        .then(({ data, headers }) => {
          const newBlob = new Blob([data]);
          const filename = headers['content-disposition'].replace('attachment; filename=', '');
          saveAs(newBlob, filename);
        })
        .finally(() => {
          this.$store.commit('HIDE_FULLSCREEN_LOADER');
          this.loadingExportBtn = false;
        });
    },
  },
};
</script>
