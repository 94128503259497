<template>
  <div class="columns">
    <div class="column is-narrow">
      <InputTravelWebsite
        ref="inputTravelWebsite"
        v-model:value="travelWebsite"
        style="width: 200px;"
        @change="onFilterChange"
      />
    </div>
    <div class="column is-narrow">
      <InputProperty
        v-model:value="propertyId"
        style="width: 200px;"
        @change="onFilterChange"
      />
    </div>
    <div class="column is-narrow">
      <InputDateRange
        v-model:value="range"
        style="width: 200px;"
        :placeholder="$t('request-date-range')"
        @change="onFilterChange"
      />
    </div>
    <div class="column is-2">
      <a-button
        v-show="travelWebsite || propertyId || range.length"
        :size="size"
        style="min-width: 110px"
        type="default"
        @click="handleClear"
      >
        {{ $t('Clear') }}
      </a-button>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "request-date-range": "Request date range",
  },
  "ja": {
    "request-date-range": "期間指定",
  },
}
</i18n>

<script>
import debounce from 'lodash/debounce';
import InputProperty from '@/components/InputProperty';
import InputDateRange from '@/components/InputDateRange';
import InputTravelWebsite from '@/components/InputTravelWebsite';

export default {
  name: 'SearchFilter',
  components: {
    InputProperty,
    InputDateRange,
    InputTravelWebsite,
  },
  emits: ['filter'],
  data() {
    return {
      size: 'large',
      range: [],
      propertyId: undefined,
      travelWebsite: undefined,
    };
  },
  computed: {
    formattedRange() {
      const dateFormat = 'YYYY-MM-DD';
      const range = { dateFrom: undefined, dateTo: undefined };

      if (this.range.length) {
        range.dateFrom = this.$filters.date(this.range[0], { format: dateFormat });
        range.dateTo = this.$filters.date(this.range[1], { format: dateFormat });
      }

      return range;
    },
  },
  mounted() {
    this.propertyId = this.$route.query.propertyCode || undefined;
    this.travelWebsite = this.$route.query.travelWebsite || undefined;
    this.range = this.$route.query.dateFrom && this.$route.query.dateTo
      ? [this.$route.query.dateFrom, this.$route.query.dateTo] : [];
  },
  methods: {
    async handleFilter() {
      const query = {
        ...this.$route.query,
        ...this.formattedRange,
        propertyCode: this.propertyId,
        travelWebsite: this.travelWebsite,
      };

      await this.$router.push({ query });
      this.$emit('filter');
    },
    onFilterChange: debounce(async function onFilterChange() {
      await this.handleFilter();
    }, 600),
    handleClear() {
      this.propertyId = undefined;
      this.range = [];
      this.travelWebsite = undefined;
      this.onFilterChange();
    },
    triggerFetchTravelWebiste() {
      this.$refs.inputTravelWebsite.handleSearch();
    },
  },
};
</script>
