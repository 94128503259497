<template>
  <div class="columns is-mini-gap is-multiline">
    <div class="column is-12">
      <h3 class="has-text-primary has-text-weight-bold is-size-4">
        {{ $t('Column Configurations') }}
      </h3>
      <p class="text-desc">
        {{ $t('Select columns you want to see') }}
      </p>
    </div>
    <div class="column is-12">
      <a-checkbox-group v-model:value="checkedColumns">
        <div class="columns is-mini-gap is-multiline">
          <div
            v-for="i in columns"
            :key="i.value"
            class="column is-3"
          >
            <a-checkbox :value="i.value">
              {{ $t(i.name) }}
            </a-checkbox>
          </div>
        </div>
      </a-checkbox-group>
    </div>
    <div class="column is-12">
      <hr>
    </div>
    <div class="column is-12">
      <p class="has-text-weight-bold has-text-black is-size-6">
        {{ $t('Drag to rearrange the order') }}
      </p>
      <p class="text-desc">
        {{ $t('New column will be added') }}
      </p>
      <div class="my-4">
        <div class="is-flex" style="overflow: auto; padding-bottom: 20px;">
          <div
            v-for="(i, idx) in selectedColumns"
            :key="i.value"
            class="dropzone"
            @dragover="onDragOver"
            @drop="onDrop($event, idx)"
            @dragend="handleDragEnd"
          >
            <ColumnBlock
              v-model:isDragging="isDragging"
              :data="{ ...i, index: idx }"
              class="mr-1"
              :handle-remove="handleRemoveColumn"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="column has-text-right is-12 m-t-4x">
      <a-button
        :disabled="loading"
        :size="size"
        class="button-secondary-outline m-r-3x"
        style="min-width: 115px"
        @click="$emit('cancel')"
      >
        {{ $t('Cancel') }}
      </a-button>
      <a-button
        :loading="loading"
        :size="size"
        style="min-width: 115px"
        type="primary"
        @click="handleSave"
      >
        {{ $t('Save') }}
      </a-button>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "Column Configurations": "Column Configurations",
    "Select columns you want to see": "Select columns you want to see",
    "Drag to rearrange the order": "Drag to rearrange the order",
    "New column will be added":
    "* New column will be added to above sample table, scroll to the right to view it",
    "Number of Nights": "Number of Nights",
    "Number of Guests": "Number of Guests",
    "Number of Rooms": "Number of Rooms",
    "1 Person Number of Nights": "1 Person Number of Nights",
    "2 Person Number of Nights": "2 Person Number of Nights",
    "3 Person Number of Nights": "3 Person Number of Nights",
    "4 Person Number of Nights": "4 Person Number of Nights",
    "5 Person Number of Nights": "5 Person Number of Nights",
    "6 Person Number of Nights": "6 Person Number of Nights",
    "1 Night Total": "1 Night Total",
    "2 Night Total": "2 Night Total",
    "3 Night Total": "3 Night Total",
    "4 Night Total": "4 Night Total",
    "5 Night Total": "5 Night Total",
    "6 Night Total": "6 Night Total",
    "External Website Reservation ID": "External Website Reservation ID",
    "Reservation Date": "Reservation Date",
    "OTA Website Code": "OTA Website Code",
    "External Website": "External Website",
    "External Website OTA Code": "External Website OTA Code",
    "OTA Fees": "OTA Fees",
    "OTA Fee Rates": "OTA Fee Rates",
    "Sales without OTA Fees": "Sales without OTA Fees",
    "Remarks": "Remarks",
    "Website Name": "Website Name",
    "Sales": "Sales",
    "Commission Lift Up": "Commission Lift Up",
    "Checkin Date": "Checkin Date",
  },
  "ja": {
    "Column Configurations": "項目設定",
    "Select columns you want to see": "表示する項目を設定してください",
    "Drag to rearrange the order": "項目をドラッグすると表示する順序を変更できます",
    "New column will be added":
    "追加した項目は下にサンプルとして表示されます。項目が多い場合は右にスクロールすると全項目を確認できます",
    "Number of Nights": "宿泊数",
    "Number of Guests": "宿泊人数",
    "Number of Rooms": "部屋数",
    "1 Person Number of Nights": "１名泊数",
    "2 Person Number of Nights": "２名泊数",
    "3 Person Number of Nights": "３名泊数",
    "4 Person Number of Nights": "４名泊数",
    "5 Person Number of Nights": "５名泊数",
    "6 Person Number of Nights": "６名以上泊数",
    "1 Night Total": "１泊件数",
    "2 Night Total": "２泊件数",
    "3 Night Total": "３泊件数",
    "4 Night Total": "４泊件数",
    "5 Night Total": "５泊件数",
    "6 Night Total": "６泊以上件数",
    "External Website Reservation ID": "外部予約ID",
    "Reservation Date": "予約日",
    "OTA Website Code": "OTAサイトコード",
    "External Website": "外部予約サイト",
    "External Website OTA Code": "外部予約OTAサイトコード",
    "OTA Fees": "OTA手数料",
    "OTA Fee Rates": "OTA手数料率",
    "Sales without OTA Fees": "OTA手数料抜き売上",
    "Remarks": "備考(楽トラ/Booking用)",
    "Website Name": "サイト名",
    "Sales": "売上",
    "Commission Lift Up": "リフトアップ",
    "Checkin Date": "チェックイン日",
  }
}
</i18n>

<script>
import ColumnBlock from './column-configuration/ColumnBlock';
import {
  columns,
  defaultColumns,
  columnMap,
} from './column-configuration/columnsList';

export default {
  name: 'ColumnConfiguration',
  components: {
    ColumnBlock,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['cancel', 'close'],
  data() {
    return {
      loading: false,
      size: 'large',
      isDragging: false,
      columns,
      selectedColumns: defaultColumns,
    };
  },
  computed: {
    checkedColumns: {
      get() {
        return this.selectedColumns.map((el) => el.value);
      },
      set(val) {
        if (val.length > this.selectedColumns.length) {
          const col = columnMap[val[val.length - 1]];
          this.selectedColumns.push(col);
        } else {
          this.selectedColumns = val.map((el) => columnMap[el]);
        }
      },
    },
  },
  watch: {
    value: {
      handler(nv) {
        // dont mutate the props obj
        this.selectedColumns = [...nv];
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    async handleSave() {
      this.loading = true;
      const activeColumns = [];

      for (let i = 0; i < this.selectedColumns.length; i += 1) {
        const { value } = this.selectedColumns[i];

        activeColumns.push(value);
      }

      await this.$store.dispatch('rms-toplines/saveConfiguration', { activeColumns });

      this.loading = false;
      this.$emit('close');
    },
    onDrop(evt, toIndex) {
      this.isDragging = false;
      const column = evt.dataTransfer.getData('value');
      const temp = this.selectedColumns[column];
      this.selectedColumns.splice(column, 1);
      this.selectedColumns.splice(toIndex, 0, temp);
    },
    onDragOver(evt) {
      evt.preventDefault();
    },
    handleDragEnd() {
      this.isDragging = false;
    },
    handleRemoveColumn(index) {
      this.selectedColumns.splice(index, 1);
    },
  },
};
</script>

<style scoped>
.text-desc {
  font-size: 13px;
}
</style>
