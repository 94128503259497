<template>
  <a-range-picker
    v-model:value="range"
    :style="styleProps"
    :size="size"
    :format="dateFormat"
    @change="handleChange"
  >
    <a-input
      :value="dateString"
      :placeholder="placeholder || $t('date range')"
      :size="size"
      readonly
    >
      <template #prefix>
        <template v-if="hasIcon">
          <slot name="icon" />
        </template>
        <template v-else>
          <CalendarOutlined />
        </template>
      </template>
    </a-input>
  </a-range-picker>
</template>

<script>
import { CalendarOutlined } from '@ant-design/icons-vue';

export default {
  name: 'Inputrange',
  components: {
    CalendarOutlined,
  },
  props: {
    size: {
      type: String,
      default: 'large',
    },
    styleProps: {
      type: Object,
      default: () => {},
    },
    placeholder: {
      type: String,
      default: undefined,
    },
    rangeString: {
      type: String || Boolean,
      default: false,
    },
    dateFormat: {
      type: String,
      default: 'DD/MM/YYYY',
    },
    value: {
      type: String || Array,
      default: () => [],
    },
  },
  emits: ['update:value', 'change'],
  data() {
    return {
      range: [],
    };
  },
  computed: {
    hasIcon() {
      return this.$slots && this.$slots.icon;
    },
    dateString() {
      if (this.rangeString) {
        return this.rangeString;
      }

      if (this.range.length === 0) {
        return null;
      }

      const from = this.$filters.date(this.range[0], { format: this.dateFormat });
      const to = this.$filters.date(this.range[1], { format: this.dateFormat });

      return `${from} ~ ${to}`;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        this.range = nv;
      },
    },
  },
  methods: {
    handleChange() {
      this.$emit('change', this.range);
      this.$emit('update:value', this.range);
    },
  },
};
</script>
