export const columnMap = {
  propertyCode: { name: 'Property Code', value: 'propertyCode' },
  websiteName: { name: 'Website Name', value: 'websiteName', width: 120 },
  otaWebsiteCode: { name: 'OTA Website Code', value: 'otaWebsiteCode', width: 120 },
  reservationNumber: { name: 'Reservation Number', value: 'reservationNumber', width: 130 },
  reservationDate: { name: 'Reservation Date', value: 'reservationDate', width: 120 },
  checkin: { name: 'Checkin Date', value: 'checkin', width: 120 },
  checkout: { name: 'Checkout Date', value: 'checkout', width: 120 },
  status: { name: 'Status', value: 'status', width: 120 },
  totalSalesAmount: { name: 'Sales', value: 'totalSalesAmount', width: 150 },
  salesWithoutOtaFees: { name: 'Sales without OTA Fees', value: 'salesWithoutOtaFees' },
  otaFees: { name: 'OTA Fees', value: 'otaFees', width: 80 },
  otaFeeRates: { name: 'OTA Fee Rates', value: 'otaFeeRates' },
  numOfGuests: { name: 'Number of Guests', value: 'numOfGuests' },
  roomNumber: { name: 'Room Number', value: 'roomNumber' },
  remarks: { name: 'Remarks', value: 'remarks' },
  numOfRoom: { name: 'Number of Room', value: 'numOfRoom' },
  numOfNights: { name: 'Number of Nights', value: 'numOfNights' },
  externalWebsite: { name: 'External Website', value: 'externalWebsite' },
  externalWebsiteOtaCode: { name: 'External Website OTA Code', value: 'externalWebsiteOtaCode' },
  externalWebsiteReservationId: { name: 'External Website Reservation ID', value: 'externalWebsiteReservationId' },
  propertyName: { name: 'Property Name', value: 'propertyName', width: 160 },
  commissionLiftUp: { name: 'Commission Lift Up', value: 'commissionLiftUp' },
  onePersonNumOfNights: { name: '1 Person Number of Nights', value: 'onePersonNumOfNights' },
  twoPersonNumOfNights: { name: '2 Person Number of Nights', value: 'twoPersonNumOfNights' },
  threePersonNumOfNights: { name: '3 Person Number of Nights', value: 'threePersonNumOfNights' },
  fourPersonNumOfNights: { name: '4 Person Number of Nights', value: 'fourPersonNumOfNights' },
  fivePersonNumOfNights: { name: '5 Person Number of Nights', value: 'fivePersonNumOfNights' },
  sixPersonNumOfNights: { name: '6 Person Number of Nights', value: 'sixPersonNumOfNights' },
  oneNightTotal: { name: '1 Night Total', value: 'oneNightTotal' },
  twoNightTotal: { name: '2 Night Total', value: 'twoNightTotal' },
  threeNightTotal: { name: '3 Night Total', value: 'threeNightTotal' },
  fourNightTotal: { name: '4 Night Total', value: 'fourNightTotal' },
  fiveNightTotal: { name: '5 Night Total', value: 'fiveNightTotal' },
  sixNightTotal: { name: '6 Night Total', value: 'sixNightTotal' },
};

export const columns = Object.values(columnMap);

export const defaultColumns = columns.slice(0, 5);
