<template>
  <a-dropdown>
    <template #overlay>
      <a-menu class="add-topline-dropdown-menu" @click="handleMenuClick">
        <input
          ref="file"
          type="file"
          accept=".csv"
          hidden
          @change="onFileChange"
        >
        <a-button :class="btnClass" @click="$refs.file.click()">
          {{ $t('import-csv') }}
        </a-button>
        <a-button :class="btnClass" @click="onAddManually">
          {{ $t('enter-manually') }}
        </a-button>
      </a-menu>
    </template>
    <a-button :class="btnClass">
      {{ $t('add-topline') }}
      <CaretDownOutlined />
    </a-button>
  </a-dropdown>
</template>

<i18n>
{
  "en": {
    "add-topline": "Add Topline",
    "import-csv": "Import CSV",
    "enter-manually": "Enter Manually",
  },
  "ja": {
    "add-topline": "トップラインを追加",
    "import-csv": "CSVインポート",
    "enter-manually": "直接入力",
  },
}
</i18n>

<script>
import { CaretDownOutlined } from '@ant-design/icons-vue';

export default {
  name: 'AddToplineButton',
  components: {
    CaretDownOutlined,
  },
  props: {
    onAddManually: {
      type: Function,
      default: () => {},
    },
  },
  emits: ['added'],
  data() {
    return {
      btnClass: 'ant-btn ant-btn-lg ant-btn-primary has-text-left',
      isVisible: false,
    };
  },
  methods: {
    onFileChange(e) {
      this.$store.commit('SHOW_FULLSCREEN_LOADER');

      this.$store.dispatch('rms-toplines/imports', {
        file: e.target.files[0],
      }).then(() => {
        this.$emit('added');
      }).finally(() => {
        this.$store.commit('HIDE_FULLSCREEN_LOADER');
      });
    },
  },
};
</script>

<style scoped>
.add-topline-dropdown-menu {
  background-color: #00596c;
  display: flex;
  flex-direction: column;
}
</style>
