<template>
  <div
    class="is-flex is-flex-direction-column"
    draggable="true"
    @dragstart="handleDragStart"
  >
    <div
      class="table-block mt-3"
      :style="{
        position: 'relative',
        opacity: isDragging ? .6 : 1,
      }"
    >
      {{ $t(data.name) }}
      <CloseCircleTwoTone
        two-tone-color="red"
        class="delete-icon"
        :style="{
          position: 'absolute',
          fontSize: '20px',
          top: '-5px',
        }"
        @click="handleRemove(data.index)"
      />
    </div>
    <div class="table-dummy-dot">
      . . .
    </div>
    <div class="table-dummy-dot">
      . . .
    </div>
  </div>
</template>

<script>
import { CloseCircleTwoTone } from '@ant-design/icons-vue';

export default {
  name: 'ColumnBlock',
  components: {
    CloseCircleTwoTone,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    isDragging: Boolean,
    handleRemove: {
      type: Function,
      default: () => {},
    },
  },
  emits: ['update:isDragging'],
  methods: {
    handleDragStart(evt) {
      this.$emit('update:isDragging', true);
      evt.dataTransfer.setData('value', this.data.index);
    },
  },
};
</script>

<style lang="scss" scoped>
.table-block {
  background-color: black;
  color: white;
  padding: 8px 14px;
  font-size: 14px;
  width: max-content;
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.table-dummy-dot {
  padding: 0 15px;
}

.delete-icon {
  display: none;
  cursor: pointer;
}
.table-block:hover .delete-icon {
  display: initial;
}
</style>
